import React, { useEffect, useState } from 'react'
import { CarObject, CreditObject, UserObject, useStore } from '../../../store/Store'
import { useNavigate, useParams } from 'react-router-dom'
import { httpGetRequest } from '../../../host/Host'
import { useTranslation } from 'react-i18next'
import { filterPrice } from '../../../components/Alert'
import { MdArrowForwardIos } from 'react-icons/md'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaCalculator, FaFacebookF, FaGlobe, FaInstagram } from 'react-icons/fa6'
import { GiRotaryPhone } from 'react-icons/gi'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'

export default function OneAutosalon() {
    const [data, setdata]=useState<UserObject | null>(null)
    const [type, settype]=useState<number | null>(null)
   
    const [cars, setcars]=useState<CarObject[]>([])
const {id}=useParams()
const {t}=useTranslation()
const {setloader}=useStore()
const navigate=useNavigate()
    useEffect(()=>{
        getData()
    }, [])

    const getData=async()=>{
        setloader(true)
        try{
           var res=await httpGetRequest('/public-users/', {user_type:"avtosalon", id:id})
            if(res.data!=null && res.data.length>0){
                setdata(res.data[0])
                GetIpoteka()
                setloader(false)
            }else{
                setloader(false)
                navigate('/')
            }
          
       }catch(err){
           console.log(err)
       }
   }
   const GetIpoteka=async()=>{
    try{
       var res1=await httpGetRequest(`/cars/${id}`)
       setcars(res1.data.results)
      
   }catch(err){
       console.log(err)
   }
}
  return (
    data!=null? <div className='main_banks_box open_bank_box one_car'>
       <div className='one_bank_info '>
        <div className='one_bank_text'>
            <div className='one_bank_head'>
                <img src={data.image}/>
                <h1>{t("lang")==='uz'?data.first_name:data.last_name}</h1>
            </div>
            <p dangerouslySetInnerHTML={{__html:t("lang")==='uz'?data[`description`]:data[`description_ru`]}} />
        </div>
        <div style={{backgroundImage:`url(${data.image2})`}} className='one_bank_img'></div>
       </div>
       <div className="bank_contact">
        <a href={data.telegram} className='telegram_btn' target='_blank'><FaTelegramPlane /> <span>{t('telegram')}</span></a>
        <a href={data.facebook} className='facebook_btn' target='_blank'><FaFacebookF /> <span>{t('facebook')}</span></a>
        <a href={data.instagram} className='instagram_btn' target='_blank'><FaInstagram /> <span>{t('instagram')}</span></a>
        <a href={data.website} className='website_btn' target='_blank'><FaGlobe /> <span>{t('website')}</span></a>
        <a href={`tel: ${data.phone}`} className='bank_phone' target='_blank'><GiRotaryPhone/> <span>{data.phone}</span></a>
        

       </div>
       
      
      
       
        <h1 className='title_bank'>{t("cars")}</h1>
        <div className='credits'>
            {cars.map((item,key)=>{
                return(<div className='credit_item' >
                    <div className='credit_item_img' style={{backgroundImage:`url(${item?.images[0].image})`}}></div>
                     <div className='product_text'>
                            
                                <div className='product_text_head'>
                                <div className='head_text_title'>
                                <h1>{item.name}</h1>
                                </div>
                                </div>
                                <div className='car_one_box'>
            {item.ctype!=null?<div className='product_item'>
            <b>{t("car_type")} :</b><span> {item.ctype[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            
            {item.model!=null?<div className='product_item'>
            <b>{t("model")} :</b><span> {item.model[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.position!=null?<div className='product_item'>
            <b>{t("position")} :</b><span> {item.position[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.kuzov!=null?<div className='product_item'>
            <b>{t("kuzov")} :</b><span> {item.kuzov[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.color!=null?<div className='product_item'>
            <b>{t("car_color")} :</b><span> {item.color[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.color_state!=null?<div className='product_item'>
            <b>{t("color_state")} :</b><span> {item.color_state[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.transmission!=null?<div className='product_item'>
            <b>{t("transmission")} :</b><span> {item.transmission[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.drive_unit!=null?<div className='product_item'>
            <b>{t("drive_unit")} :</b><span> {item.drive_unit[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
           
            {item.engine_type!=null?<div className='product_item'>
            <b>{t("engine_type")} :</b><span> {item.engine_type[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {item.autosalon!=null?<div className='product_item'>
            <b>{t("autosalon_has")} :</b><span> {item.autosalon?t("yes"):t("no")}</span>
            </div>:<></>}
            {item.long_term_pay!=null?<div className='product_item'>
            <b>{t("long_term_pay_has")} :</b><span> {item.long_term_pay?t("yes"):t("no")}</span>
            </div>:<></>}
            {item.rent!=null?<div className='product_item'>
            <b>{t("rent_has")} :</b><span> {item.rent?t("yes"):t("no")}</span>
            </div>:<></>}
            {item.probeg!=null?<div className='product_item'>
            <b>{t("probeg")} :</b><span> {item.probeg}</span>
            </div>:<></>}
            {item.capacity!=null?<div className='product_item'>
            <b>{t("capacity")} :</b><span> {item.capacity}</span>
            </div>:<></>}</div>
                               </div>
                               <button>{t("info_all")} <MdArrowForwardIos className='one_bank_icon' /> <input type="checkbox"/></button>
                                <div className='desc_credit_item'>{item.additional!==null && item.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
                                   <p dangerouslySetInnerHTML={{__html:item.additional}}/> 
                                </div>:''}
                                </div>
                             </div>
                    )
            })}
        </div>
       
    </div>:<></>
   
  )
}
