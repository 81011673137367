import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NewsObject, useStore } from '../../../store/Store'
import { httpGetRequest } from '../../../host/Host'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'antd'

export default function OpenNew() {
    const {id}=useParams()
    const {setloader}=useStore()
    const navigate=useNavigate()
    const [data, setdata]=useState<NewsObject | null>(null)
    const {t}=useTranslation()
    useEffect(()=>{
     if(isNaN(Number(id))){
         navigate("/")
     }else{
        setloader(true)
        getData()
     }
        
    }, [])
     const getData=async()=>{
       
        try{
           var res=await httpGetRequest(`/news/${id}/`)
           setdata(res.data)
           setloader(false)
        }catch(err){
            setloader(false)
            navigate("/")
        }
     }
  return (
    <div className='news_box news_one_box'>
       {data!=null?<div>
        <h1>{t('lang')=='uz'?data.title_uz:data.title_ru}</h1>
        <div className='opne_new_images'>  
         <Carousel fade>
            {data.images.map((item, key)=>{
                return(<div>
                    <div className='opne_new_img'>
                    <img src={item.image}/>
                    </div>
                  </div>)
            })}
          
         </Carousel>
         </div>
        <p dangerouslySetInnerHTML={{__html:t('lang')=='uz'?data.description_uz:data.description_ru}} />
       </div>:<></>}
    </div>
  )
}
