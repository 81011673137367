import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import home from '../../../images/menu_icons/home.png'
import home_service from '../../../images/menu_icons/home_service.png'
import home_product from '../../../images/menu_icons/home_product.png'
import home_credit from '../../../images/menu_icons/home_credit.png'
import car from '../../../images/menu_icons/car.png'
import car_credit from '../../../images/menu_icons/car_credit.png'
import car_product from '../../../images/menu_icons/car_product.png'
import construction from '../../../images/menu_icons/construction.png'
import car_service from '../../../images/menu_icons/car_service.png'
export default function PhoneCategories() {
    const {t}=useTranslation()
  return (
    <div className='phone_categories'>
         <div className='phone_category'><Link to={`/selectors?big_category=1&category=1`} className='phone_category_cart'>
         <div className='phone_category_img'><img src={home} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
          {t("turar_joy")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=2&category=5`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={car} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("cars")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=1&category=2`} className='phone_category_cart'>
 
    <div className='phone_category_img'><img src={home_service} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
    {t("master_service")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=2&category=6`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={car_service} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("master_service_car")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=1&category=3`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={home_product} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("material")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=2&category=7`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={car_product} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("material_car")}
         </div>
    </Link></div>
    <div className='phone_category'><Link to={`/selectors?big_category=1&category=9`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={construction} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("qurilish")}
         </div>
    </Link></div>
   
   
     
    
    
    <div className='phone_category'><Link to={`/selectors?big_category=2&category=8`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={car_credit} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("auto_credit")}
         </div>
    </Link></div> 
    <div className='phone_category'><Link to={`/selectors?big_category=1&category=4`} className='phone_category_cart'>
    <div className='phone_category_img'><img src={home_credit} alt="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping"/></div>
    <div className='phone_category_text'>
         {t("ipoteka")}
         </div>
    </Link></div> 
       </div>
    

  )
}
