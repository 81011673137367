import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextEditorProps {
  value: string; // The current value of the editor
  setvalue: (content: string) => void; // Handler to update the value
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, setvalue }) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // Text formatting options
      ['blockquote', 'code-block'], // Block elements
      [{ list: 'ordered' }, { list: 'bullet' }], // Lists
      [{ indent: '-1' }, { indent: '+1' }], // Indentation
      [{ direction: 'rtl' }], // Text direction
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
      [{ color: [] }, { background: [] }], // Text and background color
      [{ font: [] }], // Fonts
      [{ align: [] }], // Alignment
      ['image'], // Insert image
      ['clean'], // Clear formatting
    ],
  };
const onChange=(str:any)=>{
    setvalue(str)
}
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'direction',
    'color',
    'background',
    'font',
    'align',
    'image',
  ];

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      className="big_quill"
      modules={modules}
      formats={formats}
    />
  );
};

export default RichTextEditor;
