import React from 'react'

  
  interface SimpleInputProps {
    label:string;
    placeholder:string;
    value: number|string;
    setvalue: (setvalue: number|string) => void;
    required?:boolean;
    percent?:boolean;
    
  }
  const SimpleInput: React.FC<SimpleInputProps>=({value, setvalue,  label, placeholder, required=false, percent=false})=>{
    const editValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      
      var val=e.target.value
      console.log(val)
      if(val.length!==0){
        if(val.indexOf('-')!==-1){
          val=val.replaceAll('-', '')
        }
        var numT=Number(val)
        if(numT<0){
          numT=0
        }
        if(percent){
            if(numT>100){
              numT=100
            }
        }
       setvalue(numT);
      }else{
        setvalue(val)
      }
     
    };
  return (
    <div className={`input`}>
        <label>{label}</label>
        <div className={`input_input  ${required && (value==='')?"red_input_box":""}`}>
           <input autoComplete="new-password" min={0} max={2000000000} placeholder={placeholder} type="number" value={value} onChange={(e)=>{editValue(e)}}/>
        </div>
      </div>
  )
}
export default SimpleInput;