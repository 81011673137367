import React, { useEffect } from 'react'
import { httpGetRequest } from '../../../host/Host'
import { useStore } from '../../../store/Store'
import logo from '../../../images/empty_img.png'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
export default function News() {
const {news, setnews}=useStore()
const {t}=useTranslation()
    useEffect(()=>{
        if(news.length==0){
            getData()
        }
     
    },[])

    const getData=async()=>{
        try{
          var res=await httpGetRequest('/news/')
          setnews(res.data)
        }catch(err){
            console.log(err)
        }
    }
  return (
    <div className='news_box'>
         <h1 className='title'>{t("news")}</h1>
        <Row className='news_row'>
       {news.map((item,key)=>{
        return(
        <Col lg={8} md={12} sm={24} className='news_col'>
        <Link to={`/news/${item.id}`} className='news_card'>
            <div className='news_img' style={{backgroundImage:`url(${item.images.length>0?item.images[0].image:logo})`}}>
           
            </div>
           <p>{t('lang')=='uz'?item.title_uz:item.title_ru}</p>
        </Link></Col>)
       })}
       </Row>
    </div>
  )
}
