import React, { useEffect, useState } from 'react'
import { CreditObject, UserObject, useStore } from '../../../store/Store'
import { useNavigate, useParams } from 'react-router-dom'
import { httpGetRequest } from '../../../host/Host'
import { useTranslation } from 'react-i18next'
import { filterPrice } from '../../../components/Alert'
import { MdArrowForwardIos } from 'react-icons/md'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaCalculator, FaFacebookF, FaGlobe, FaInstagram } from 'react-icons/fa6'
import { GiRotaryPhone } from 'react-icons/gi'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'

export default function OneBank() {
    const [data, setdata]=useState<UserObject | null>(null)
    const [type, settype]=useState<number | null>(null)
   
    const [ipotekas, setipotekas]=useState<CreditObject[]>([])
    const [avtokredits, setavtokredits]=useState<CreditObject[]>([])
const {id}=useParams()
const {t}=useTranslation()
const {setloader}=useStore()
const navigate=useNavigate()
    useEffect(()=>{
        getData()
    }, [])

    const getData=async()=>{
        setloader(true)
        try{
           var res=await httpGetRequest('/public-users/', {user_type:"bank", id:id})
            if(res.data!=null && res.data.length>0){
                setdata(res.data[0])
                GetIpoteka()
                GetAvtoKredit()
                setloader(false)
            }else{
                setloader(false)
                navigate('/')
            }
          
       }catch(err){
           console.log(err)
       }
   }
   const GetIpoteka=async()=>{
    try{
       var res1=await httpGetRequest(`/ipoteka/${id}`)
       setipotekas(res1.data.results)
      
   }catch(err){
       console.log(err)
   }
}
const GetAvtoKredit=async()=>{
    try{
       var res1=await httpGetRequest(`/auto-credits/${id}`)
       setavtokredits(res1.data.results)
      
   }catch(err){
       console.log(err)
   }
}

  return (
    data!=null? <div className='main_banks_box open_bank_box'>
       <div className='one_bank_info'>
        <div className='one_bank_text'>
            <div className='one_bank_head'>
                <img src={data.image}/>
                <h1>{t("lang")==='uz'?data.first_name:data.last_name}</h1>
            </div>
            <p dangerouslySetInnerHTML={{__html:t("lang")==='uz'?data[`description`]:data[`description_ru`]}} />
        </div>
        <div style={{backgroundImage:`url(${data.image2})`}} className='one_bank_img'></div>
       </div>
       <div className="bank_contact">
        <a href={data.telegram} className='telegram_btn' target='_blank'><FaTelegramPlane /> <span>{t('telegram')}</span></a>
        <a href={data.facebook} className='facebook_btn' target='_blank'><FaFacebookF /> <span>{t('facebook')}</span></a>
        <a href={data.instagram} className='instagram_btn' target='_blank'><FaInstagram /> <span>{t('instagram')}</span></a>
        <a href={data.website} className='website_btn' target='_blank'><FaGlobe /> <span>{t('website')}</span></a>
        <a href={`tel: ${data.phone}`} className='bank_phone' target='_blank'><GiRotaryPhone/> <span>{data.phone}</span></a>
        <a href={data.website} className='calculator_btn' target='_blank'> <FaCalculator /><span>{t('calculator')}</span></a>

       </div>
       {/* <div className='bank_map'><YMaps>
             <Map state={{
               center: [data.latitude, data.longitude],
               zoom: 14,
             }} width="100%" height="450px" options={{
               minZoom: 5,
               maxZoom: 20,
             }}>
               <Placemark geometry={[data.latitude, data.longitude]} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
               <ZoomControl options={{ float: 'left' }} />
             </Map>
          
           </YMaps>
           </div> */}
       <div className='bank_btns'>
        <button onClick={()=>{settype(null)}} className={`${type===null?"bank_active_btn":""}`}>{t("all")}</button>
        <button onClick={()=>{settype(1)}} className={`${type===1?"bank_active_btn":""}`}>{t("ipotekas")}</button>
        <button onClick={()=>{settype(2)}} className={`${type===2?"bank_active_btn":""}`}>{t("avtokredits")}</button>
       </div>
      
       {(type===null || type===1) && ipotekas.length!=0?<>
        <h1 className='title_bank'>{t("ipotekas")}</h1>
        <div className='credits'>
            {ipotekas.map((item,key)=>{
                return(<div className='credit_item' >
                    <div className='credit_item_img' style={{backgroundImage:`url(${item?.images[0].image})`}}></div>
                     <div className='product_text'>
                            
                                <div className='product_text_head'>
                                <div className='head_text_title'>
                                <h1>{item.name}</h1>
                                </div>
                                </div>
                                <div className='product_item'>
                                <b>{t("credit_summ")}:</b><span> {filterPrice(item.summ_min)+" so'm - "+filterPrice(item.summ_max)+t(t(" so'm"))}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("percent_credit")}:</b><span> {filterPrice(item.p_min)+(item.p_max===item.p_min || item.p_max===null?"":" - "+filterPrice(item.p_max))+" %"}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("start_payment_credit")}:</b><span> {filterPrice(item.init_pay_percent_min)+(item.init_pay_percent_max===item.init_pay_percent_min || item.init_pay_percent_max===null?"":" - "+filterPrice(item.init_pay_percent_max))+" %"}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("credit_month_credit")}:</b><span> {item.month_min+(item.month_max===item.month_min || item.month_max===null?"":" - "+item.month_max)+" oy"}</span>
                                </div>
                                {item.bonus_month!=null?<div className='product_item'>
                                <b>{t("bonus_month_credit")} :</b><span> {item.bonus_month} oy</span>
                                </div>:<></>}
                               </div>
                               <button>{t("info_all")} <MdArrowForwardIos className='one_bank_icon' /> <input type="checkbox"/></button>
                                <div className='desc_credit_item'>{item.additional!==null && item.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
                                   <p dangerouslySetInnerHTML={{__html:item.additional}}/> 
                                </div>:''}
                                </div>
                             </div>
                    )
            })}
        </div>
       </>:<></>}
       {(type===null || type===2) && avtokredits.length!=0?<>
        <h1 className='title_bank'>{t("avtokredits")}</h1>
        <div className='credits'>
            {avtokredits.map((item,key)=>{
                return(<div className='credit_item' >
                      <div className='credit_item_img' style={{backgroundImage:`url(${item?.images[0].image})`}}></div>
                     <div className='product_text'>
                            
                                <div className='product_text_head'>
                                <div className='head_text_title'>
                                <h1>{item.name}</h1>
                                </div>
                                </div>
                                <div className='product_item'>
                                <b>{t("credit_summ")}:</b><span> {filterPrice(item.summ_min)+" so'm - "+filterPrice(item.summ_max)+t(t(" so'm"))}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("percent_credit")}:</b><span> {filterPrice(item.p_min)+(item.p_max===item.p_min || item.p_max===null?"":" - "+filterPrice(item.p_max))+" %"}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("start_payment_credit")}:</b><span> {filterPrice(item.init_pay_percent_min)+(item.init_pay_percent_max===item.init_pay_percent_min || item.init_pay_percent_max===null?"":" - "+filterPrice(item.init_pay_percent_max))+" %"}</span>
                                </div>
                                <div className='product_item'>
                                <b>{t("credit_month_credit")}:</b><span> {item.month_min+(item.month_max===item.month_min || item.month_max===null?"":" - "+item.month_max)+" oy"}</span>
                                </div>
                                {item.bonus_month!=null?<div className='product_item'>
                                <b>{t("bonus_month_credit")} :</b><span> {item.bonus_month} oy</span>
                                </div>:<></>}
                               </div>
                               <button>{t("info_all")} <MdArrowForwardIos className='one_bank_icon' /> <input type="checkbox"/></button>
                                <div className='desc_credit_item'>{item.additional!==null && item.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
                                   <p dangerouslySetInnerHTML={{__html:item.additional}}/> 
                                </div>:''}
                                </div>
                             </div>
                    )
            })}
        </div>
       </>:<></>}
    </div>:<></>
   
  )
}
