import { useTranslation } from "react-i18next"
import { dangerAlert, formatPayment } from "../../components/Alert"
import { useStore } from "../../store/Store"
import click from '../../images/click.png'
import { Modal } from "antd"
import { useState } from "react"
import { httpPostRequest } from "../../host/Host"
export const Payments=()=>{
    const {user, token_access, balance, setloader, setbalance}=useStore()
    const {t}=useTranslation()
    const [show, setshow]=useState(false)
    const [summa, setsumma]=useState("")
    const handleOk=()=>{
     
        var sum_str=Number(summa.replaceAll(" ", ""))
           if(isNaN(sum_str) || sum_str<=0){
            dangerAlert(t('write_summa'))
           }else{
            setloader(true)
            httpPostRequest("/billing/click/create/", {"amount":String(sum_str)}).then(res=>{
              
              if(res!=null && res.data!=null){
                 window.location.href=`https://my.click.uz/services/pay?service_id=${res.data.service_id}&merchant_id=${res.data.merchant_id}&amount=${res.data.amount}&transaction_param=${res.data.transaction_param}&return_url=${window.location.href}`
              }else{
                dangerAlert(t("server_error_text"))
              }
                 
            }).catch(err=>{
              dangerAlert(t("server_error_text"))
            })
           }
      }
      const handleCancel=()=>{
           setshow(false)
      }
      const onchange=(e)=>{
        var val=e.target.value
        if(val.length!==0){
          val=Number(val.replace(/\D/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
        setsumma(val)
      }
return <div className="announcment_client">
    <div className="payments">
          <div className="payments_head">
            <div><p>{t("balance")}:</p> <b>{formatPayment(balance)} so'm</b></div>
            <div><p>{t("add_payment")}:</p><button onClick={()=>{setshow(true)}}><img src={click}/></button></div>
            
          </div>
    </div>
    <Modal okText={t('pay')} cancelText={t('close')} title={t("add_payment")} open={show} onOk={handleOk} onCancel={handleCancel}>
  <input className='input_summa' type='text' placeholder={t("summa")} value={summa} onChange={onchange} />       
</Modal>
</div>
}