import { Col, Row, Tooltip } from 'antd'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SimpleSelect from '../../../../components/SimpleSelect'
import { closeSelect} from '../../../../components/Data'
import SimpleInput from '../../../../components/SimpleInput'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import SimpleInputText from '../../../../components/SimpleInputText'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { TypeObject, useStore } from '../../../../store/Store'
import { FaTriangleExclamation } from 'react-icons/fa6'
import { dangerAlert, formatPhoneNumber, scrollDivToTop } from '../../../../components/Alert'
import { httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../../host/Host'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleInputPhone from '../../../../components/SimpleInputPhone'
import { useTranslation } from 'react-i18next'
export default function HomeConstructionAnnoucmentCreate() {
    const {region_data, district_data, setdistrict_data, house_construction_type_data}=useStore()
    const [product_type, setproduct_type]=useState(0)
    const [subproduct_type, setsubproduct_type]=useState(0)
    const [name, setname]=useState('')
    const [images, setimages]=useState<File[]>([])
    const [video, setvideo]=useState<File[]>([])
    const [region, setregion]=useState(0)
    const [image, setimage]=useState<number|null>(null)
    const [district, setdistrict]=useState(0)
    const [facebook, setfacebook]=useState('')
    const [instagram, setinstagram]=useState('')
    const [website, setwebsite]=useState('')
    const [telegram, settelegram]=useState('')
    const [phone, setphone]=useState('')
    const [price_uzs, setprice_uzs]=useState<number|string>('')
    const [price_usd, setprice_usd]=useState<number|string>('')
    const [additional, setadditional] = useState<string>('');
    const [required, setrequired]=useState(false)
    const [number, setnumber]=useState(1)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const [subproduct_type_data, setsubproduct_type_data]=useState<TypeObject[]>([])
    const {setloader, user}=useStore()
    const navigate=useNavigate()
    const {id}=useParams()
    const {t} =useTranslation()
    useEffect(()=>{
      
      if(user!=null){
        setphone(formatPhoneNumber(user.phone))
        settelegram(user.telegram)
        setfacebook(user.facebook)
        setwebsite(user.website)
        setinstagram(user.instagram)
        if(user.region!=null){
          setregion(user.region.id); handleregion(user.region.id)
        }
        if(user.district!=null){
          setdistrict(user.district.id)
        }
      }
    }, [user])
    const onFinish=async()=>{
        if(phone!=null){
          var phone_new=phone.replaceAll("_", "")
        }else{
          var phone_new=""
        }
        
        if(name.length!==0 && phone_new.length===19 && region!==0 && price_uzs!=='' && price_usd!=='' && product_type!==0){
          if(images.length===0){
            dangerAlert(t("error_image"))
          }else{
            setloader(true)
            var data={
              name,
              price_uzs:String(price_uzs),
              price_usd:String(price_usd),
              phone,
              instagram:instagram.length===0?null:instagram,
              facebook:facebook.length===0?null:facebook,
              website:website.length===0?null:website,
              telegram:telegram.length===0?null:telegram,
              region:region!==0?String(region):null,
              district:district!==0?String(district):null,
              product_type:product_type!==0?String(product_type):null,
              subproduct_type:subproduct_type!==0?String(subproduct_type):null,
              additional,
              video:video.length===0?null:video[0]
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
              if (value !== null) {
                formData.append(key, value);
                
              }
            }
            
            try{
              var res=await httpPostRequest(`/houses-products/create/`, formData)
              if(images.length!=0){
                await Promise.all(images.map(async(item, key:number)=>{
                  var config=new FormData
                  config.append('image', item)
                  config.append('product', res.data.id)
                  config.append('main', key === image ? 'true' : 'false')
                  try{
                    var res1=await httpPostRequest(`/houses/products/images/create/`, config)
                    if(image!==null && key===image){
                      try{
                        var res2=await httpPatchRequest(`/houses-products/${res.data.id}/update/`, {image:res1.data.id})
                      }catch(err2){
                        console.log(err2)
                      }
                      
                    }
                  }catch(err1){
                    console.log(err1)
                  }
                }))
                setloader(false)
                navigate("/client/home/construction")
              }
            }catch(err){
              setloader(false)
              dangerAlert(t("Error"))
              console.log(err)
            }
          
          }
         
        }else{
          dangerAlert(t("error_not_full"))
          setrequired(true)
          scrollDivToTop()
        }
    }
   
    const handleEditorChange = (content: string) => {
        setadditional(content);
      };
    const handlechange=()=>{

    }
    const restoreFilter=()=>{

    }
   
      const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
      
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 10) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(`${t("lang")==='uz'?selectedFiles.length-filesArray.length:''} ${t("error_image_mb")}`)
          }
          
          setimages([...filesArray.slice(0, 10-images.length), ...images]);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
      
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 100) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(t("error_video_mb"))
          }
          
          setvideo(filesArray)
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const deleteImage=(key:number)=>{
        var a=images
        a.splice(key,1)
setimages(a)
setnumber(number+1)
      }
      const handleregion=async(id:number)=>{
        setdistrict(0)
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id)
         setdistrict_data(res.data)
    
      }
      const handleproduct_type=async(id:number)=>{
        setsubproduct_type(0)
        setsubproduct_type_data([])
        var res=await httpGetRequest('/houses-qurilish-products/subtypes/', {product_type:id})
         setsubproduct_type_data(res.data)
    
      }
  return (
    <div  onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <div className={`announcment_filter client_open_filter`}>
        <h1 className='create_title'>{t("add_elon")}</h1>
           
            <div className='announcment_filter_body'>
            <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{t("alert_add_elon")}</div>
        <Row>
        <Col  className='filter_col' lg={8} md={24} sm={24}>
                <SimpleInputText max={70} required={required} placeholder={""} label={t("name_add")} value={name} setvalue={setname}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required} onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect required={false} onchange={handlechange} name="district" label={t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  required={required} onchange={handleproduct_type} name="product_type" label={t("product_type")} value={product_type} setvalue={setproduct_type} data={house_construction_type_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlechange} name="subproduct_type" label={t("subproduct_type")} value={subproduct_type} setvalue={setsubproduct_type} data={subproduct_type_data}/>
            </Col>
       
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_uzs")} value={price_uzs} setvalue={setprice_uzs}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_usd")} value={price_usd} setvalue={setprice_usd}/>
            </Col>
           
        
            <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label={t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
            <Col lg={16} md={12} sm={24}></Col>
           
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("additional")}</label>
           <div className='quill_box'> <ReactQuill
        theme="snow" // You can choose different themes like 'bubble' or 'snow'
        value={additional}
        onChange={handleEditorChange}
      /></div>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_video")}</label>
            <input ref={videoInputRef} onChange={handleVideoChange} className='file_input' accept="video/*" type='file' />
            <p className='file_text'>{t("alert_video")}</p>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
              <br/>
              <br/>
            <label>{t("select_image")}</label>
            <input ref={fileInputRef} onChange={handleFileChange} className='file_input' accept="image/*" type='file' multiple />
            <p className='file_text'>{t("alert_image")}</p>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}></Col>
            
            {images.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={URL.createObjectURL(item)}/>
                       <div onClick={()=>{deleteImage(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       <input className='active_image' onChange={()=>setimage(key)} type="radio" name="image" value={key} checked={key===image} />
                    </div>
                    
                </Col>)
            })}
        </Row>
        <div className='filter_btns'>
         
          <button onClick={onFinish} className='save_btn'>{t("save")}</button>
        </div>
        </div>
        </div>
       
    </div>
  )
}
