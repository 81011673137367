import { message } from "antd"

export const dangerAlert=(content:string)=>{
    message.error(content, 3)
} 
export const successAlert=(content:string)=>{
    message.success(content, 3)
} 
export const formatDate=(content:string|null)=>{
    if(content!==null){
        const date = new Date(content);

// Get date components
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
const day = String(date.getDate()).padStart(2, "0");
const hours = String(date.getHours()).padStart(2, "0");
const minutes = String(date.getMinutes()).padStart(2, "0");

// Format the date as per your requirement
const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
return(formattedDate)
    }else{
        return '-'
    }
    
} 
export function formatPhoneNumber(phone: string | null): string {
    if (!phone) {
        return ''; // Return an empty string if phone is null or empty
    }
    let dat=phone.replaceAll("+", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")
    const countryCode = dat.slice(0, 3); // +998
    const areaCode = dat.slice(3, 5);   // (99)
    const firstPart = dat.slice(5, 8);  // 999
    const secondPart = dat.slice(8, 10); // 99
    const thirdPart = dat.slice(10, 12); // 99

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`;
}
export function isValidEmail(email:string) {
    // Regular expression for a basic email validation
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    // Use the test method of the regular expression to check if the email is valid
    return emailPattern.test(email);
}

export function scrollDivToTop() {
    const div = document.querySelector('.announcment_client'); // Replace 'your-div-id' with the actual ID of your div
    if (div) {
        div.scrollTo({
            top: 0,
            behavior: 'smooth' // Enable smooth scrolling
        });
    }
}
export function filterPrice(a:number|string|null){
    
    if(a===null || a===undefined){
         return('-')
    }else{
        if(typeof a==='number'){
            a=String(a)
        }
        if(a.indexOf('.')!=-1){
            a=a.slice(0, a.indexOf("."))
        }
       
       
        const number = Number(a)
        
        if(!isNaN(number)){
           const formattedNumber = number.toLocaleString('en-US').replace(/,/g, ' ');
            
            return(formattedNumber)
        }else{
            return('-')
        }
        
    }
}

export const checkArrayData=(a:string|null)=>{
      if(a!==null && a!=='[]'){
        return(a)
      }else{
        return(null)
      }
}

export const formatTelegram=(a:string)=>{
    if(a.indexOf("https://t.me/")==-1){
        a=a.replaceAll("/", '')
        a="https://t.me/"+a
    }
    return(a)
}
export const formatInstagram=(a:string)=>{
    if(a.indexOf("https://www.instagram.com/")==-1){
        a=a.replaceAll("/", '')
        a="https://www.instagram.com/"+a
    }
    return(a)
}
export const formatFacebook=(a:string)=>{
    if(a.indexOf("https://www.facebook.com/")==-1){
        a=a.replaceAll("/", '')
        a="https://www.facebook.com/"+a
    }
    return(a)
}
export const formatwebsite=(a:string)=>{
    if(a.indexOf("https://")==-1){
        a=a.replaceAll("/", '')
        a=a.replaceAll("www.", '')
        a="https://"+a
    }
    return(a)
}
export const formatPayment=(number:number)=>{
    if(number!=null){
        var formattedNumber = number.toLocaleString('ru-RU').replace(/,/g, ' ')
    }else{
        var formattedNumber="0"
    }
   
   return(formattedNumber)
}
export const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
