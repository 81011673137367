import React from 'react'
import { useTranslation } from 'react-i18next'
import service_home from '../../../images/dash_categories/service_home.jpg'
import credit_car from '../../../images/dash_categories/creadit_car.jpg'
import credit_home from '../../../images/dash_categories/creadit_home.jpg'
import service_car from '../../../images/dash_categories/service_car.jpg'
import material_car from '../../../images/dash_categories/material_car.jpg'
import material_home from '../../../images/dash_categories/material_home.jpg'
import construction from '../../../images/dash_categories/construction.jpg'
import { Link } from 'react-router-dom'
export default function CategoriesDash() {
    const {t}=useTranslation()
  return (
    <div className='dash_categories'>
      
      <Link to={'/selectors?big_category=1&category=2'} className='categories_card'>
            <h1> {t("master_service")}</h1>
            <img src={service_home}/>
        </Link>
       
        <Link to={'/selectors?big_category=1&category=3'} className='categories_card'>
            <h1> {t("material")}</h1>
            <img src={material_home}/>
        </Link>
        <Link to={'/selectors?big_category=1&category=9'} className='categories_card'>
            <h1> {t("qurilish")}</h1>
            <img src={construction}/>
        </Link>
        <Link to={'/selectors?big_category=1&category=4'} className='categories_card'>
            <h1> {t("ipoteka")}</h1>
            <img src={credit_home}/>
        </Link>
        <Link to={'/selectors?big_category=2&category=6'} className='categories_card'>
            <h1> {t("master_service_car")}</h1>
            <img src={service_car}/>
        </Link>
        <Link to={'/selectors?big_category=2&category=7'} className='categories_card'>
            <h1> {t("material_car")}</h1>
            <img src={material_car}/>
        </Link>
        <Link to={'/selectors?big_category=2&category=8'} className='categories_card'>
            <h1> {t("auto_credit")}</h1>
            <img src={credit_car}/>
        </Link>
     
    </div>
  )
}
