import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdArrowForward } from 'react-icons/io';
import { useStore } from '../../../store/Store';
import { httpGetRequest } from '../../../host/Host';
import { filterPrice } from '../../../components/Alert';
import logo from '../../../images/logo.png'
import { Link } from 'react-router-dom';
export default function Bests(){
    const {t}=useTranslation()
    const {best_car, best_home, setbest_home, setbest_car, money_type}=useStore()
    useEffect(()=>{
       getBests()
    }, [])

    const getBests=async()=>{
        if(best_car.length===0){
            try{
           var res=await httpGetRequest('/houses/', {is_top:true, status:1})
           setbest_home(res.data.results)
                    }catch(err){
                     console.log(err)
                    }
          }
          if(best_car.length===0){
            try{
           var res=await httpGetRequest('/cars/', {is_top:true, status:1})
           setbest_car(res.data.results)
                    }catch(err){
                     console.log(err)
                    }
          }
    }
    return(
        <div className="bests">
        <h1 className='title'>{t("bests")}</h1>
        <Row className="best_row">
        <Col className="best_col" lg={12} md={24} sm={24}>
        {best_home.map((item,key)=>{
            return(<Link to={'/product/home/'+item.id} key={key} className='best_card'>
                <div className='best_img'>
                 <div style={{backgroundImage:`url(${item.image!=null?item.image.image:''})`}} className='best_img_img best_home'></div>
                </div>
                <div className='best_text'>
                 <h2>{item.name}</h2>
                 <p>{item.region!=null?item.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {item.district!=null?item.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</p>
                 <h1>{money_type===2?filterPrice(item.price_usd)+" $":filterPrice(item.price_uzs)+t(t(" so'm"))}</h1>
                </div>
                <div className='best_arrow'>
                <IoMdArrowForward />
                </div>
            </Link>)
        })}
          
             
          </Col>
          <Col className="best_col" lg={12} md={24} sm={24}>
          {best_car.map((item,key)=>{
            return(<Link to={'/product/car/'+item.id} key={key} className='best_card'>
                <div className='best_img'>
                 <div style={{backgroundImage:`url(${item.image!=null?item.image.image:''})`}} className='best_img_img best_home'></div>
                </div>
                <div className='best_text'>
                 <h2>{item.name}</h2>
                 <p>{item.region!=null?item.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {item.model!=null?item.model[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {item.position!=null?item.position[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {item.year}, {item.transmission!=null?item.transmission[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</p>
                 <h1>{money_type===2?filterPrice(item.price_usd)+" $":filterPrice(item.price_uzs)+t(t(" so'm"))}</h1>
                </div>
                <div className='best_arrow'>
                <IoMdArrowForward />
                </div>
            </Link>)
        })}
         </Col>
        </Row>
      </div>
    )
}