
import { IoClose, IoHome, IoMenu, IoSettingsSharp } from 'react-icons/io5'
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { FaCar } from 'react-icons/fa6';
import { GiAutoRepair, GiCarWheel, GiTakeMyMoney } from 'react-icons/gi';
import { RiPaintBrushFill } from 'react-icons/ri';
import { GrPaint } from "react-icons/gr";
import { useStore } from '../../store/Store';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import home from '../../images/menu_icons/home.png'
import home_service from '../../images/menu_icons/home_service.png'
import home_product from '../../images/menu_icons/home_product.png'
import home_credit from '../../images/menu_icons/home_credit.png'
import car from '../../images/menu_icons/car.png'
import money from '../../images/menu_icons/money.png'
import car_credit from '../../images/menu_icons/car_credit.png'
import car_product from '../../images/menu_icons/car_product.png'
import setting from '../../images/menu_icons/setting.png'
import car_service from '../../images/menu_icons/car_service.png'
import construction from '../../images/menu_icons/construction.png'
import exit from '../../images/menu_icons/exit.png'
interface SimpleMenuProps {
    className:string;
    
    
  }
const SideBar: React.FC<SimpleMenuProps>=({className})=>{
    const {user, open, setopen, setuser}=useStore()
    let location = useLocation();
    const [loc, setloc]=useState(1)
    const navigate=useNavigate()
    const {t} =useTranslation()
    useEffect(()=>{
       
            if(location.pathname.indexOf('/home/home')!==-1){
                setloc(2)
            }else{
                if(location.pathname.indexOf('home/product')!==-1){
                    setloc(4)
                }else{
                    if(location.pathname.indexOf('car/product')!==-1){
                        setloc(8)
                    }else{
                        if(location.pathname.indexOf('/car/car')!==-1){
                            setloc(6)
                        }else{
                            if(location.pathname.indexOf('/home/service')!==-1){
                                setloc(3)
                            }else{
                                
                                if(location.pathname.indexOf('/car/service')!==-1){
                                    setloc(7)
                                }else{
                                    if(location.pathname.indexOf('/home/credit')!==-1){
                                        setloc(5)
                                    }else{
                                        if(location.pathname.indexOf('/car/credit')!==-1){
                                            setloc(9)
                                        }else{
                                            if(location.pathname.indexOf('/payments')!==-1){
                                                setloc(10)
                                            }else{
                                                if(location.pathname.indexOf('home/construction')!==-1){
                                                    setloc(11)
                                                }else{
                                                    setloc(1)
                                                }
                                            }
                                        }
                                    }
                                    
                                }
                                
                            }
                           
                        }
                       
                    }
                    
                }
                
            }
           if(window.innerWidth<=767){
            setopen(false)
           }
        
    }, [location])
    const [swipeDirection, setSwipeDirection] = useState('');

  let touchStartX = 0;
  let touchEndX = 0;

  // Function to handle the start of the touch event
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX = e.targetTouches[0].clientX; 
  };

  // Function to handle the end of the touch event
  const handleTouchEnd = () => {
   
    if ( touchStartX-touchEndX > 50) {
        setopen(false)  // Swiped right
    } 
  };

  // Function to track the touch movement
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX = e.targetTouches[0].clientX; // Record the ending X position
  };
const logout=()=>{
    window.localStorage.removeItem("access_token_elon")
    setuser(null)
    navigate('/')
  }
  return (
    <>
    <div onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd} className={`client_menu ${open?"open_side_bar":''} ${className}`}>
    <div className={`client_menu_header`}>
   
            <button onClick={()=>setopen(!open)} className='menu_tab'>
            {open?<IoClose />:<IoMenu />}
            </button>
    </div>
    <div className='client_menu_list'>
    <Link className={`client_list_item ${loc===1?"active_list_item":''}`} to="/client/">
    <img src={setting}/>
            <p>{t("parametr")}</p>
        </Link>
       
        <Link className={`client_list_item ${loc===10?"active_list_item":''}`} to="/client/payments">
    <img src={money}/>
            <p>{t("payments")}</p>
        </Link>
       
       
        {user!=null && (user.user_type==='user' || user.user_type==='bank')?<>
        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("houses")}</p>
        </div>
        <Link className={`client_list_item ${loc===2?"active_list_item":''}`} to="/client/home/home">
            <img src={home}/>
            <p>{t("houses")}</p>
        </Link>
        {user!=null && user.user_type==='user'?<>
        <Link className={`client_list_item ${loc===3?"active_list_item":''}`} to="/client/home/service">
        <img src={home_service}/>
            <p>{t("master_service")}</p>
        </Link>
        <Link className={`client_list_item ${loc===4?"active_list_item":''}`} to="/client/home/product">
        <img src={home_product}/>
            <p>{t("material")}</p>
        </Link>
        <Link className={`client_list_item ${loc===11?"active_list_item":''}`} to="/client/home/construction">
        <img src={construction}/>
            <p>{t("qurilish")}</p>
        </Link></>:<></>}
        <Link className={`client_list_item ${loc===5?"active_list_item":''}`} to="/client/home/credit">
        <img src={home_credit}/>
            <p>{t("ipoteka")}</p>
        </Link>
        </div>
</>:<></>}
        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("cars")}</p>
        </div>
        <Link className={`client_list_item ${loc===6?"active_list_item":''}`} to="/client/car/car">
        <img src={car}/>
            <p>{t("cars")}</p>
        </Link>
        {user!=null && (user.user_type==='user' || user.user_type==='avtosalon')?<><Link className={`client_list_item ${loc===7?"active_list_item":''}`} to="/client/car/service">
        <img src={car_service}/>
            <p>{t("master_service_car")}</p>
        </Link>
        <Link className={`client_list_item ${loc===8?"active_list_item":''}`} to="/client/car/product">
        <img src={car_product}/>
            <p>{t("material_car")}</p>
        </Link></>:<></>}
        <Link className={`client_list_item ${loc===9?"active_list_item":''}`} to="/client/car/credit">
        <img src={car_credit}/>
            <p>{t("auto_credit")}</p>
        </Link>
        </div>
        <div onClick={logout} className={`client_list_item exit_menu_side`}>
        <img src={exit}/>
            <p>{t("exit")}</p>
        </div>
        
        
        
    </div> 
    </div>
    </>
    
  )
}

export default SideBar