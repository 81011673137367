'use client'

import React, { useState } from 'react'
import InputMask from 'react-input-mask';
import { useStore } from '../store/Store';

  
  interface SimpleInputPhoneProps {
    label:string;
    placeholder:string;
    value: string;
    type?: string;
    required?: boolean;
    setvalue: (setvalue: string) => void;
    
  }
  const SimpleInputPhone: React.FC<SimpleInputPhoneProps>=({value, setvalue, label, placeholder, required=false, type="text"})=>{
    const [mask_text, setmask_text]=useState(19)
    const {user}=useStore()
    const editValue = (e: React.ChangeEvent<HTMLInputElement>) => {

      var val=e.target.value
       
        setvalue(val)
     
     
    };
  return (
    <div className={`input`}>
        <label>{label}</label>
        <div className={`input_input  ${required && (value===null)?"red_input_box":""}`}>
        {user!=null && user.user_type==='user'? <InputMask 
           mask="+\9\98 (99) 999-99-99"
           maskChar="_" type={type} placeholder={placeholder} value={value!==null?value:''} onChange={(e:any)=>{editValue(e)}}/>
           :<input autoComplete="new-password"
            type={type} placeholder={placeholder} value={value!==null?value:''} onChange={(e:any)=>{editValue(e)}}/>}
        </div>
      </div>
  )
}
export default SimpleInputPhone;