import React, { useEffect } from 'react'
import { useStore } from '../../../store/Store'
import { useTranslation } from 'react-i18next'
import { httpGetRequest } from '../../../host/Host'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import { FaPhoneFlip } from 'react-icons/fa6'
import { GiRotaryPhone } from 'react-icons/gi'


export default function Banks() {
    const {banks, setbanks}=useStore()
    const {t}=useTranslation()
        useEffect(()=>{
            if(banks.length==0){
                getData()
            }
         
        },[])
    
        const getData=async()=>{
            try{
              var res=await httpGetRequest('/public-users/', {user_type:"bank"})
              setbanks(res.data)
            }catch(err){
                console.log(err)
            }
        }
  return (
    banks.length>0?<><div className='main_banks_box'>
       <h1 className='title'>{t("banks")}</h1>
    <div className='main_banks'>
      
      {banks.map((item,key)=>{
        return(<div className='bank_col' >
          <Link to={`/bank/${item.id}`} className='bank_card'>
            <div className='bank_img'>
              <img src={item.image} alt={item.first_name}/>
            </div>
            <div className='bank_text'>
              <p>{item.first_name}</p>
              <a><GiRotaryPhone  className='bank_icon'/> - {item.phone}</a>
            </div>
          </Link>
        </div>)
      })}
      </div>
    </div></>:<></>
  )
}
