import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import SimpleInputText from '../../components/SimpleInputText'
import SimpleSelect from '../../components/SimpleSelect'
import { FaPencil, FaTriangleExclamation } from 'react-icons/fa6'
import { useStore } from '../../store/Store'
import { httpPostRequest, httpPatchRequest, httpGetRequest } from '../../host/Host'
import { dangerAlert } from '../../components/Alert'
import avatar from '../../images/icons/random_avatar_man.jpg'
import SimpleInputPhone from '../../components/SimpleInputPhone'
import { useTranslation } from 'react-i18next'
import RichTextEditor from '../../components/ReactQuill'
import { IoSearch } from 'react-icons/io5'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { API_KEY } from '../../components/Data'
import { useNavigate } from 'react-router-dom'
export default function Profile() {

    const {user, setloader, setuser, district_data, region_data, setdistrict_data}=useStore()
    const [required, setrequired]=useState(false)
    const [required_password, setrequired_password]=useState(false)
    const [first_name, setfirst_name]=useState('')
    const [image, setimage]=useState<File|null>(null)
    const [image2, setimage2]=useState<File|null>(null)
    const [image_src, setimage_src]=useState<string | null>(null);
    const [image_src2, setimage_src2]=useState<string | null>(null);
    const [password, setpassword]=useState('')
    const [confirm_password, setconfirm_password]=useState('')
    const [region, setregion]=useState(0)
    const [district, setdistrict]=useState(0)
    const [facebook, setfacebook]=useState('')
    const [description, setdescription]=useState<string>('')
    const [description_ru, setdescription_ru]=useState<string>('')
    const [instagram, setinstagram]=useState('')
    const [website, setwebsite]=useState('')
    const [telegram, settelegram]=useState('')
    const [address, setaddress]=useState('')
    const [phone, setphone]=useState('')
    const [last_name, setlast_name]=useState('')
    const [coors, setcoors]=useState<number[]>([41.355178,69.287824])
    const [gender, setgender]=useState(0)
    const [loc_select, setloc_select]=useState<boolean>(false)
    const {t} =useTranslation()
    const navigate=useNavigate()
    const handleChange=()=>{

    }
    useEffect(()=>{
        if(user!==null){
            setfirst_name(user.first_name!=null?user.first_name:'')
            settelegram(user.telegram!=null?user.telegram:'')
            setdescription(user.description!=null?user.description:'')
            setdescription_ru(user.description_ru!=null?user.description_ru:'')
            setaddress(user.address!=null?user.address:'')
            if(user.latitude!=null && user.longitude!=null){
                setcoors([user.latitude, user.longitude])
            
            }
            setfacebook(user.facebook!=null?user.facebook:'')
            setinstagram(user.instagram!=null?user.instagram:'')
            setlast_name(user.last_name!=null?user.last_name:'')
            setphone(user.phone!=null?user.phone:'')
            setwebsite(user.website!=null?user.website:'')
            if(user.region!=null){
                setregion(user.region.id)
                handleregion(user.region.id)
            }
            setgender(user.gender!=null?user.gender==="male"?1:2:0)
            
        }
        
    }, [user])
    const handleClick = (e: any) => {
        setloc_select(true)
        const coords = e.get('coords');
        setcoors(coords);
      };
    const sendUserData=async()=>{
        if(first_name.length!==0){
           setloader(true)
           var data=new FormData
           data.append("first_name", first_name)
           data.append("last_name", last_name)
           data.append("address", address)
           data.append("description", description)
           data.append("description_ru", description_ru)
           data.append("latitude", String(coors[0]))
           data.append("longitude", String(coors[1]))
           data.append("phone", phone)
           data.append("instagram", instagram)
           data.append("telegram", telegram)
           data.append("facebook", facebook)
           data.append("website", website)
           if (image !== null) {
            data.append("image", image)
           }
           if (image2 !== null) {
            data.append("image2", image2)
           }
          
           if (region !== 0) {
            data.append("region", String(region));
          }
          
          if (gender !== 0) {
            if(gender===1){
                data.append("gender", "male");
            }else{
                data.append("gender", "female");
            }
           
          }
          
          if (district !== 0) {
            data.append("district", String(district));
          }
           try{
            var res=await httpPatchRequest('/user/update/', data)
            setuser(res.data)
            setloader(false)
            setimage(null)
            setimage2(null)
            setimage_src(null)
            setimage_src2(null)
            navigate("/client/home/home")
        }catch(error){
            console.log(error)
            setloader(false)
        }
            
        }else{
            setrequired(true)
        }
    }
    const handleSearch = async () => {
          if (address) {
            try {
              const response = await fetch(
                `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${encodeURIComponent(
                  address
                )}`
              );
              const data = await response.json();
              if(data.response.GeoObjectCollection.featureMember.length>0){
                const pos =
                data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
              const [lon, lat] = pos.split(" ").map(Number);
              console.log([lon, lat])
              setcoors([lat, lon]); 
              }
              
            } catch (error) {
              console.error("Error fetching coordinates:", error);
            }
          }
        };
    const changePassword=async()=>{
        if(password.length!==0 && confirm_password.length!==0){
            if(password!==confirm_password){
                dangerAlert(t("parword_not_equal"))
               }else{
           setloader(true)
           try{
            var res=await httpPostRequest('/change-password/', {password, confirm_password})
            setloader(false)
           
        }catch(error){
            console.log(error)
            setloader(false)
    
           }
        }
        }else{
            setrequired_password(true)
        }
    }
    const uploadImage=(e: React.ChangeEvent<HTMLInputElement>)=>{
        if(e.target && e.target.files && e.target.files.length>0){
            setimage(e.target.files[0])
            const file = e.target.files?.[0]; // Get the first selected file
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setimage_src(reader.result as string); // Set the image state to the base64 string
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            }
            }else{
                setimage(null)
                setimage_src(null)
            }
    }
    const uploadImage2=(e: React.ChangeEvent<HTMLInputElement>)=>{
        if(e.target && e.target.files && e.target.files.length>0){
            setimage2(e.target.files[0])
            const file = e.target.files?.[0]; // Get the first selected file
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setimage_src2(reader.result as string); // Set the image state to the base64 string
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            }
            }else{
                setimage2(null)
                setimage_src2(null)
            }
    }
    const handleregion=async(id:number)=>{
        setdistrict(0)
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id)
        setdistrict_data(res.data)
        if(user?.region!==null && id===user?.region.id){
            setdistrict(user.district!=null?user.district.id:0)
        } 
      }
  return (
    <div className='announcment_client'>
    <div className='profile'>
        
        <div className='profile_img'>
        <div className='images_btns'>
                <button className='images_btn edit_btn'><input onChange={uploadImage} accept='image/*' type='file'/><FaPencil /></button>
            </div>
            <div className='profile_avatar' style={{backgroundImage:`url(${image_src!=null?image_src:user!==null && user.image!==null?user.image:avatar})`}} />

{user?.user_type!=='user'?<div className='main_img_user'> <div className='images_btns'>
                <button className='images_btn edit_btn'><input onChange={uploadImage2} accept='image/*' type='file'/><FaPencil /></button>
            </div>
            <div className='profile_avatar' style={{backgroundImage:`url(${image_src2!=null?image_src2:user!==null && user.image2!==null?user.image2:avatar})`}} /></div>:<></>}
           
        <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{ t("alert_user_profile")}</div>
        </div>
        <div className='profile_box'>
            <Row>
                <Col className='filter_col' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>{user!=null && user.user_type==='user'?t("self_info"):t("org_info")}</h1>
                </Col>
            <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText required={required} placeholder={""} label={user!=null && user.user_type==='user'?t("firstname"):t("organization")} value={first_name} setvalue={setfirst_name}/>
            </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText required={required} placeholder={""} label={user!=null && user.user_type==='user'?t("lastname"):t("organization_ru")} value={last_name} setvalue={setlast_name}/>
                </Col>
                
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label={t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                {user!=null && user.user_type==='user'?
                <>
                
                <Col className='filter_col no_overflow'lg={12} md={24} sm={24}>
                <SimpleSelect onchange={handleChange} name="big_category" label={t("gender")} value={gender} setvalue={setgender} data={[
                    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
                    {id:2, name_ru:"Женщина", name_uz:"Ayol"},
                ]}/>
            </Col>
           </>:<></>}
           {user!=null && (user.user_type==='user'  || user.user_type==='avtosalon')?<>
            <Col className='filter_col' lg={12} md={12} sm={24}>
                <SimpleSelect onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
           {user.user_type==='user'? <Col className='filter_col' lg={12} md={12} sm={24}>
                <SimpleSelect onchange={handleChange} name="district" label={t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>:<></>}
           
           </>:<></>}
            {user!=null && user.user_type!=='user'?
            <>
            <Col className='filter_col no_overflow'lg={12} md={24} sm={24}></Col>
            <Col className='filter_col' lg={24} md={24} sm={24}>
            <label>{t("description")}</label>
        <RichTextEditor value={description} setvalue={setdescription}/>
        <br/>
        <br/>
    </Col>
    <Col className='filter_col' lg={24} md={24} sm={24}>
                <label>{t("description_ru")}</label>
            <RichTextEditor value={description_ru} setvalue={setdescription_ru}/>
            <br/>
            <br/>
        </Col>
        <Col className='filter_col' lg={24} md={24} sm={24}>
            <label>{t("select_address")}</label>
            <div className={`yandex_box`}>
              <div className='yandex_input'>
            <SimpleInputText  handlesearch={handleSearch}  placeholder={t("address")} label={t("")} value={address} setvalue={setaddress}/>
            <button onClick={handleSearch}><IoSearch /></button>
            </div>
            <YMaps>
      <Map onClick={handleClick} state={{
        center: coors,
        zoom: 14,
      }} width="100%" height="100%" options={{
        minZoom: 5,
        maxZoom: 20,
      }}>
        <Placemark geometry={coors} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
        <ZoomControl options={{ float: 'left' }} />
      </Map>
   
    </YMaps>
    </div>
            </Col>
        </>
            :<></>}
            <Col className='filter_col filter_two' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>{t("socials")}</h1>
                </Col>
            <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
                
            </Row>
            <div className='filter_btns'>
            <button onClick={sendUserData} className='save_btn'>{t("save")}</button>
        </div>
        <Row>
        <Col className='filter_col filter_two' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>{t("change_password")}</h1>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText type='password' required={required_password} placeholder={""} label={t("new_password")} value={password} setvalue={setpassword}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText type='password' required={required_password} placeholder={""} label={t("check_password")} value={confirm_password} setvalue={setconfirm_password}/>
                </Col>
        </Row>
        <div className='filter_btns'>
            <button onClick={changePassword} className='save_btn'>{t("change_password")}</button>
        </div>
        </div>
    </div></div>
  )
}
