import { register } from 'module';
import React from 'react'

  
  interface SimpleInputTextProps {
    label:string;
    placeholder:string;
    value: string;
    type?: string;
    required?: boolean;
    max?:number;
    setvalue: (setvalue: string) => void;
    handlesearch?: () => void;
    
  }
  const SimpleInputText: React.FC<SimpleInputTextProps>=({value, setvalue, label, placeholder, required=false, type="text", max=null, handlesearch=null})=>{
    const editValue = (e: React.ChangeEvent<HTMLInputElement>) => {

      var val=e.target.value
      if(val.length!==0){
        if(max!==null){
          val=val.slice(0, max)
        }
      
       setvalue(val);
      }else{
        setvalue(val)
      }
     
    };
  return (
    <div className={`input`}>
        <label>{label}</label>
        <div className={`input_input  ${required && (value===null || value.length===0)?"red_input_box":""}`}>
           <input autoComplete="new-password"  type={type} placeholder={placeholder} value={value!==null?value:''} onChange={(e)=>{editValue(e)}}/>
        </div>
      </div>
  )
}
export default SimpleInputText;